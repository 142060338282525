<template>
  <JsonEditorVue
    :mode="mode"
    :content="content"
    :onChange="(updatedContent) => { $emit('change', updatedContent) }"
    :parser="jsonParser"
    :onRenderMenu="onRenderMenu" />
</template>

<script>
import JsonEditorVue from 'json-editor-vue';
import { stringify, parse } from 'lossless-json';

export default {
  name: 'JsonEditorComponent',
  components: { JsonEditorVue },
  props: ['content', 'mode'],
  data() {
    return {
      jsonParser: { stringify, parse },
    };
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    onRenderMenu(menu) {
      return menu.slice(4);
    },
  },
};
</script>

<style>
  .jse-button {
    padding: 8px !important;
  }
  .jse-message {
    padding-right: 52px !important;
  }
</style>
