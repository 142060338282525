<template>
  <div id="app">
    <v-app>
      <v-main class="page">
        <v-container
          fluid
          class="editor-container">
          <div class="editor-wrapper">
            <JsonEditor
              class="editor"
              mode="text"
              :content="textValue"
              @change="textChanged" />
            <v-btn
              fab
              small
              bottom
              right
              absolute
              color="primary"
              class="editor-transform"
              @click="transformTextToTree">
              <v-icon small>mdi-subdirectory-arrow-right</v-icon>
            </v-btn>
          </div>
          <div class="editor-wrapper">
            <JsonEditor
              class="editor"
              mode="tree"
              :content="treeValue"
              @change="treeChanged" />
            <v-btn
              fab
              small
              bottom
              left
              absolute
              color="primary"
              class="editor-transform"
              @click="transformTreeToText">
              <v-icon small>mdi-subdirectory-arrow-left</v-icon>
            </v-btn>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { stringify, parse } from 'lossless-json';
import JsonEditor from './components/JsonEditor';

export default {
  name: 'NSoftJsonEditor',
  components: { JsonEditor },
  data() {
    return {
      treeValue: { json: {} },
      textValue: { json: {} },
    };
  },
  methods: {
    textChanged(value) {
      try {
        this.textValue = value;
      } catch (error) {
        // continue regardless of error
      }
    },
    treeChanged(value) {
      try {
        this.treeValue = value;
      } catch (error) {
        // continue regardless of error
      }
    },
    transformTextToTree() {
      this.treeValue = this.clone(this.textValue);
    },
    transformTreeToText() {
      this.textValue = this.clone(this.treeValue);
    },
    clone(value) {
      return parse(stringify(value));
    },
  },

};
</script>

<style>
.page {
  width: 100vw;
  height: 100vh;
  margin: 0;
  --jse-main-border: 1px solid var(--jse-theme-color);
}

.editor-container {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 16px 8px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.editor-transform {
  margin-bottom: 1.25em;
}

.editor {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.editor-wrapper {
  padding: 8px;
  position: relative;
  flex-basis: 100%;
  max-width: 100%;
  height: 50%;
}

@media (orientation: landscape) {
  .editor-wrapper {
    flex-basis: 50%;
    max-width: 50%;
    height: 100%;
  }
}
</style>
