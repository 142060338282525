import { render, staticRenderFns } from "./JsonEditor.vue?vue&type=template&id=5446a732&"
import script from "./JsonEditor.vue?vue&type=script&lang=js&"
export * from "./JsonEditor.vue?vue&type=script&lang=js&"
import style0 from "./JsonEditor.vue?vue&type=style&index=0&id=5446a732&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports